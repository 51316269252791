/* eslint-disable @typescript-eslint/no-unused-vars */
/* tslint:disable:no-empty-interface */
// eslint-disable-next-line @typescript-eslint/no-empty-interface

export const APP_FEATURE_KEY = 'app';
import { createFeature, createReducer, on } from '@ngrx/store';
import { signalStore, withState, withHooks, patchState } from '@ngrx/signals';
import { withAppShellMethods } from './app-shell-methods';
import { fromEvent, map } from 'rxjs';
/**
 * Interface for the 'App' data used in
 */

export type Entity = object

export interface AppStatusState {
  list: Entity[]; // list of App; analogous to a sql normalized table
  selectedId: string | number | null; // which App record has been selected
  loading: boolean; // has the App list been loaded
  error: any; // last none error (if any)
  uploading: boolean;
  saved: boolean;
  scrollPosition: number;
  smallerLogo: string;
  largerLogo: string;
  mobileBrowser: boolean;
  width: number;
  height: number;
  pageYOffset: number;
  pageXOffset: number;
  showRightSidenav: boolean;
  showLeftSidenav: boolean;
  hideToolbar: boolean;
  siteName: string;
  environment: string;
  routePage: string;
  env_name: string;
}

export const initialState: AppStatusState = {
  list: [],
  selectedId: null,
  error: null,
  loading: false,
  uploading: false,
  saved: false,
  scrollPosition: 0,
  smallerLogo: 'fansay_logo_small.svg',
  largerLogo: 'fansay_logo.svg',
  mobileBrowser: false,
  width: 0,
  height: 0,
  pageYOffset: 0,
  pageXOffset: 0,
  showLeftSidenav: false,
  showRightSidenav: false,
  hideToolbar: false,
  siteName: 'SYBL',
  environment: '',
  routePage: '',
  env_name: ''
};

export const AppStatusStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withAppShellMethods(),
  withHooks({
    onInit(store) {
      if (typeof window !== 'undefined') {
          fromEvent(window, 'scroll')
            .pipe(
              map((event) => {
                const scrollPos = window.scrollY;
                  patchState(store, {scrollPosition: scrollPos})
                return; 
              })
            )
            .subscribe();
        } else {
          //
      }

    },
  })
);



export const appStatusFeature = createFeature({
  name: 'app-status',
  reducer: createReducer(initialState),
});
