import { Injectable, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthCookieService{
    constructor(
        @Inject('appUrls') appUrls:any,
        private _cookieService: CookieService
    ){ }

    setCookie(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: "Lax" | "None" | "Strict"):void{
        // First delete the cookie then set it.
        this.deleteCookie(name);
        
        return this._cookieService.set(name, value, expires, path, domain, secure, sameSite)
    }

    getCookie(name:string){
        return this._cookieService.get(name);
    }

    getAllCookies(){
        return this._cookieService.getAll()
    }

    deleteAllCookies(){
        return this._cookieService.deleteAll('/')
    }

    deleteCookie(cookieName:string){
        // Delete does not always work in this library so we set, the cookie to an expired value.
        this._cookieService.set(cookieName, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', '', true, 'None');
        
    }

}