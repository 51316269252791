/* eslint-disable @typescript-eslint/no-unused-vars */
import { IWebSocketMessage } from '@sybl/feature-websocket-models';
import { signalStore, withState, withHooks, patchState } from '@ngrx/signals';
import { Inject, inject } from '@angular/core';
import { withSyblWebsocketMethods } from './sybl-websocket-methods';
import { EnvironmentVariaablesService } from '@sybl/core-state';


import { AppUrlsService } from '@sybl/common-services'


export interface SyblWebsocketStore {
  connected: boolean; //True if connected to the WebSocketFromServer
  inTimeout: boolean;
  entities: any;
  date_time: Date;
  ids: Array<string>;
  attempts: number;
  lastChecked: number;
  nextAttempt: number; /// Have to do something with next attmept here,
  pendingIds: Array<string>;
  loaded: boolean;
  error: boolean;
  list: any;
  selectedId: string;
  appUrls: {
    AUTH_PORT: string;
    AUTH_URL: string;
    WEB_SOCKET_ADDRESS: string;
    IMAGES_SERVER_IP: string;
    IMAGES_SERVER_URL: string;
    SOC_AUTOMATION_SERVER_URL: string;
    STRIPE_SERVER_URL: string;
    API_HTTPS_SERVER: string;
    USERS_WEB_SOCKET_PORT: string;
    USERS_HTTPS_SERVER: string;
    DATA_ADMIN_WEB_SOCKET_PORT: string;
    GOOGLE_MAPS_API_KEY: string;
    API_WEB_SOCKET_ADDRESS: string;
    USERS_WEB_SOCKET_ADDRESS: string;
    DATA_ADMIN_WEB_SOCKET_ADDRESS: string;
    SMALL_LOGO: string;
    LARGE_LOGO: string;
    IMAGES_URL: string;
    AVATAR_URL: string;
    VIMEO_TOKEN: string;
    BASE_IP_ADDRESS: string;
    DEFAULT_AVATAR: string;
    REGISTERED_AVATAR: string;
  };
}

const initialState: SyblWebsocketStore = {
  ids: [],
  inTimeout: false,
  entities: {},
  connected: false,
  date_time: new Date(),
  attempts: 0,
  lastChecked: Date.now(),
  nextAttempt: Date.now(),
  pendingIds: [],
  loaded: false,
  error: false,
  list: [],
  selectedId: '',
  appUrls: {
    AUTH_PORT: '',
    AUTH_URL: '',
    WEB_SOCKET_ADDRESS: '',
    IMAGES_SERVER_IP: '',
    IMAGES_SERVER_URL: '',
    SOC_AUTOMATION_SERVER_URL: '',
    STRIPE_SERVER_URL: '',
    API_HTTPS_SERVER: '',
    USERS_WEB_SOCKET_PORT: '',
    USERS_HTTPS_SERVER: '',
    DATA_ADMIN_WEB_SOCKET_PORT: '',
    GOOGLE_MAPS_API_KEY: '',
    API_WEB_SOCKET_ADDRESS: '',
    USERS_WEB_SOCKET_ADDRESS: '',
    DATA_ADMIN_WEB_SOCKET_ADDRESS: '',
    SMALL_LOGO: '',
    LARGE_LOGO: '',
    IMAGES_URL: '',
    AVATAR_URL: '',
    VIMEO_TOKEN: '',
    BASE_IP_ADDRESS: '',
    DEFAULT_AVATAR: '',
    REGISTERED_AVATAR: '',
  },
};

export const SyblWebsocketStore = signalStore(
  { providedIn: 'root' },
//  withDevtools('syblWebsocket'), // <-- add this
  withState(initialState),
  withSyblWebsocketMethods(),
  withHooks({
    onInit(store) {
      const appUrlService = inject(AppUrlsService)
      patchState(store, {appUrls: appUrlService.appUrls })
      //if (store.loadFromLocalStorage()) {
      // return;
      // }
      // store.load(1);
    },
  })
);



/*
export const authStatusFeature = createFeature({
  name: 'auth-status',
  reducer: createReducer(
    initialState,/*

*/
