export enum EnumWSMessageStatus{
    'off',                      // 0
    'pending',                  // 1    #2cb5bc;
    'readying_to_send',    	    // 2	
    'sending_to_server',        // 3	
    'sending_from_server',      // 4	
    'received',                 // 6
    'awaiting_approval',        // 7    #bf5700
    'accepted',                 // 8    #124d02
    'declined',                 // 9    #4d0202
    'completed',                // 10
    'error',                    // 11
    'retry',                    // 12,
    'flagged',                  // 13
    'review_needed',            // 14
    'revision_needed',          // 15
    'blocked',                  // 16 
    'immutable',                // 17
    'using_local_version',      // 18
    'local_version_differs',    // 19    
    'unauthorized'              // 20
}