export enum KillBillBillingPeriodEnum {
  Daily="DAILY",
  Weekly="WEEKLY",
  BiWeekly="BIWEEKLY",
  Thirty_Days="THIRTY_DAYS",
  ThirtyOneDays="THIRTY_ONE_DAYS",
  Sixty_Days="SIXTY_DAYS",
  Ninety_Days="NINETY_DAYS",
  Monthly="MONTHLY",
  BiMestrial="BIMESTRIAL",
  Quaterly="QUARTERLY",
  Triannual="TRIANNUAL",
  BiAnnual="BIANNUAL",
  Annual="ANNUAL",
  Sesquiennial="SESQUIENNIAL",
  Biennial="BIENNIAL",
  Triennial="TRIENNIAL",
  No_Billing_Period="NO_BILLING_PERIOD",
}

export enum KillBillProductCategoryEnum{
    Base="BASE", 
    Add_On="ADD_ON", 
    Standalone="STANDALONE"
}

export enum KillBillPhaseTypeEnum{
    Trial="TRIAL",
    Discount="DISCOUNT",
    Fixed_Term="FIXEDTERM",
    Evergreen="EVERGREEN",
}

export enum KillBillSubscriptionStateEnum{
    Pending="PENDING", 
    Active="ACTIVE", 
    Blocked="BLOCKED", 
    Cancelled="CANCELLED", 
    Expired="EXPIRED", 
}

export enum KillBillSourceTypeEnum{
    Native="NATIVE",
    Migrated="MIGRATED",
    Transferred="TRANSFERRED",
}

export enum KillBillCurrencyEnum{
    US_Dollar="USD",
    Canadian_Dollar="CAD"
}

export enum KillBillPlanIDs{
    individualAnnual="individual-annual",
    individualMonthly="individual-monthly",
    teamAnnual="team-annual",
    teamMonthly="team-monthly"
}