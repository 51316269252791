/* eslint-disable @typescript-eslint/no-unused-vars */
// Profile Details are the Public Facing Properties of Users.
import { EntityState } from '@ngrx/entity';
import { signalStore, withState, withHooks } from '@ngrx/signals';

import { ISyblChatWithPagination, ISyblContext } from '@sybl/feature-sybl-models';

import {
  IPersonAdvancedSearch,
  SyblUserProfile,
} from '@sybl/feature-users-models';
import { withSyblStoreMethods } from './sybl-store-methods';

/**
 * @ngrx/entity provides a predefined interface for handling
 * a structured dictionary of records. This interface
 * includes an array of ids, and a dictionary of the provided
 * model type by id. This interface is extended to include
 * any additional interface properties.
 */
export interface SyblStoreState {
  chats: {[chat_id:string]:ISyblChatWithPagination};

  loaded: boolean;
  ids: Array<string>;
  selectedChatId: string;
  welcome: {
    msg_text: string;
    contexts: Array<ISyblContext>;
    uuid: string;
    basket_id: string;
  };
  xApiKey: string;
  recentChatSearch: {
    sortDirection: string;
    sortBy: string;
    searchOperator: string;
    searchMultipleProperties: string;
    variableType: string;
    totalCount: number;
    pageSize: number;
    pageIndex: number;
    sort: boolean;
    moreToLoad: boolean;
    maxPageIndex: number;
    lastId: string;
  },
  recentChatResults:Array<ISyblChatWithPagination>,
  disabledInputs: boolean,
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
const initialState: SyblStoreState = {
  loaded: true,
  chats: {},
  xApiKey: 'advisor@2024',
  selectedChatId: undefined,
  ids: [],
  welcome: {
    msg_text: 'Please Login to use SYBL Ai powered chat',
    contexts: [],
    uuid: undefined,
    basket_id: undefined,
  },
  recentChatSearch: {
    sortDirection: 'desc',
    sortBy: 'date_time',
    variableType: "date",
    searchOperator: '',
    searchMultipleProperties: '',
    totalCount: 0,
    pageSize: 20,
    pageIndex: 0,
    sort: true,
    moreToLoad: false,
    maxPageIndex: 0,
    lastId: '',
  },
  recentChatResults: [],
  disabledInputs: true,
};

export const SyblStore = signalStore(
  { providedIn: 'root' },
  // withDevtools('syblStore'), // <-- add this
  withState(initialState),
  withSyblStoreMethods(),
  //withEntities(),
  withHooks({
    onInit(store) {
      //if (store.loadFromLocalStorage()) {
      // return;
      // }
      // store.load(1);
    },
  })
);
