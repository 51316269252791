import {
  DocumentStatusEnum,
  IDocumentAdditonsDeletions, 
  IDocumentEdits,
  IDocumentUpdate,
  IFieldChanges,
} from '@sybl/feature-documents-models';
import { ISyblMessage } from './sybl-message';

export interface ISyblChatWithPagination extends ISyblChat {
  totalMessages?: number;
  pageIndex?: number;
  pageFromDB?: number;
  title?: string;
  pageSize?: number;
  loaded?: boolean;
  previousLastUserMessage?: string; // Needed to pull into scroll on pagination
  searchField?: string; // Default to chat_id
  fieldOne?: string; // Field value
  fieldTwo?: string; //
  sortDirection: string; // asc or desc
  sortBy?: string; // Name of property
  searchOperator?: string | undefined;
  searchOperatorTwo?: string;
  searchMultipleProperties?: string;
  lastUserMessage?: string;
}


export interface ISyblChat {
  context_filter: string;
  date_time: Date;
  title?: string;
  chat_id: string; //uuid
  messages: ISyblMessage[];
  sent_messages: ISyblMessage[];
  received_messages: ISyblMessage[];
  'x-api-key': string;
  user_id: any;
}

export class SyblChat implements ISyblChatWithPagination {
  context_filter: string;
  date_time: Date;
  previousLastUserMessage?: string;
  loaded?: boolean;
  title?: string;
  messages: ISyblMessage[];
  totalMessages?: number;
  sent_messages: ISyblMessage[]; // Just the uuid
  received_messages: ISyblMessage[];
  'x-api-key': string;
  chat_id: string;
  user_id: any;
  pageIndex?: number;
  pageFromDB?: number;
  pageSize?: number;
  searchField?: string;
  fieldOne?: string;
  fieldTwo?: string;
  sortDirection: string;
  sortBy?: string;
  searchOperator?: string;
  searchOperatorTwo?: string;
  searchMultipleProperties?: string;
  lastUserMessage?: string;

  constructor(params) {
    if (params) {
      this.pageFromDB = params.pageFromDB;
      this.context_filter = params.context_filter;
      this.previousLastUserMessage = params.previousLastUserMessage;
      this.date_time = params.date_time;
      this.loaded = params.loaded;
      this.messages = params.messages;
      this.title = params.title;
      this.sent_messages = params.sent_messages;
      this.received_messages = params.received_messages;
      this['x-api-key'] = params['x-api-key'];
      this.chat_id = params.chat_id;
      this.user_id = params.user_id;
      this.pageIndex = params.pageIndex;
      this.pageSize = params.pageSize;
      this.searchField = params.searchField;
      this.fieldOne = params.fieldOne;
      this.fieldTwo = params.fieldTwo;
      this.sortDirection = params.sortDirection;
      this.sortBy = params.sortBy;
      this.searchOperator = params.searchOperator;
      this.searchOperatorTwo = params.searchOperatorTwo;
      this.searchMultipleProperties = params.searchMultipleProperties;
      this.lastUserMessage = params.lastUserMessage;
    }
  }
}

export class SyblChatUpdate extends SyblChat implements IDocumentUpdate {
  master_id?: string;
  flagged?: boolean;
  priority?: number;
  editor_id?: any;
  editor_type: string;
  status?: DocumentStatusEnum;
  wasReversion: boolean;
  additions: IDocumentAdditonsDeletions[];
  deletions: IDocumentAdditonsDeletions[];
  changes: IDocumentEdits[];
  fieldChanges: IFieldChanges[];
  reasonForUpdate: string;
  uuid: string;

  constructor(params?: any) {
    super(params);
    this.uuid = params.chat_id;
    this.context_filter = params.context_filter;
    this.date_time = params.date_time;
    this.uuid = params.uuid;
    this.master_id = params.master_id;
    this.flagged = params.flagged;
    this.priority = params.priority;
    this.editor_id = params.editor_id;
    this.editor_type = params.editor_type;
    this.status = params.status;
    this.wasReversion = params.wasReversion;
    this.additions = params.additions;
    this.deletions = params.deletions;
    this.changes = params.changes;
    this.fieldChanges = params.fieldChanges;
    this.reasonForUpdate = params.reasonForUpdate;
  }
}
