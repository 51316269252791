/* eslint-disable @typescript-eslint/no-unused-vars */
// Profile Details are the Public Facing Properties of Users.
import { EntityState } from '@ngrx/entity';
import { signalStore, withState, withHooks } from '@ngrx/signals';

import { ISyblChat, ISyblContext, ISyblInteraction } from '@sybl/feature-sybl-models';

import {
  IPersonAdvancedSearch,
  SyblUserProfile,
} from '@sybl/feature-users-models';
import { ILocation } from '@sybl/common-models';
import { withInteractionsMethods } from './interactions-methods';
import { withEntities } from '@ngrx/signals/entities';

/**
 * @ngrx/entity provides a predefined interface for handling
 * a structured dictionary of records. This interface
 * includes an array of ids, and a dictionary of the provided
 * model type by id. This interface is extended to include
 * any additional interface properties.
 */
export interface InteractionsState {
  entities: {[messsage_id:string]:ISyblInteraction},
  loaded: boolean;
  ids:Array<string>,
  selectedUuid:string
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
const initialState: InteractionsState = {
  entities: {},
  loaded: true,
  ids: [],
  selectedUuid: undefined

};

export const InteractionsStore = signalStore(
  { providedIn: 'root' },
//  withDevtools('interactions'), // <-- add this
  withState(initialState),
  withInteractionsMethods(),
  withHooks({
    onInit(store) {
      //if (store.loadFromLocalStorage()) {
      // return;
      // }
      // store.load(1);
    },
  })
);
