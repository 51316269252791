import { EntityState } from '@ngrx/entity';
import { signalStore, withState, withHooks } from '@ngrx/signals';

import {
  ISyblChatWithPagination,
  ISyblContext,
  ISyblIndustryCoverage,
  KillBillBillingPeriodEnum,
  KillBillCurrencyEnum,
  KillBillPhaseTypeEnum,
  KillBillProductCategoryEnum,
  KillBillSourceTypeEnum,
  KillBillSubscriptionStateEnum,
  SyblAgencyEmployeeCount,
  ISyblAgencyUser,
  SyblAgencyYearlyRevenue,
  SyblLicenseTypeEnum,
  SyblAgencyUser,
} from '@sybl/feature-sybl-models';

import {
  IPersonAdvancedSearch,
  SyblUserProfile,
} from '@sybl/feature-users-models';
import { ILocation } from '@sybl/common-models';
import { withSyblAgencyMethods } from './sybl-agency-methods';
import { withEntities } from '@ngrx/signals/entities';

/**
 * @ngrx/entity provides a predefined interface for handling
 * a structured dictionary of records. This interface
 * includes an array of ids, and a dictionary of the provided
 * model type by id. This interface is extended to include
 * any additional interface properties.
 */
export interface SyblAgencyState {
  loaded: boolean;
  xApiKey: string;
  agency_id: string;
  tenant: string;
  user: SyblAgencyUser;
  uuid: string;
  agency_name: string;
  purchased_seats: number;
  kill_bill_id: string;
  email: string;
  earlyAdopter: boolean;
  active_seats: number;
  inactive_seats: number;
  employeeCount: SyblAgencyEmployeeCount;
  yearlyRevenue: SyblAgencyYearlyRevenue;
  billingPeriod: KillBillBillingPeriodEnum | undefined;
  productCategory: KillBillProductCategoryEnum | undefined;
  phaseType: KillBillPhaseTypeEnum | undefined;
  subscriptionState: KillBillSubscriptionStateEnum | undefined;
  sourceType: KillBillSourceTypeEnum | undefined;
  currency: KillBillCurrencyEnum | undefined;
  licenseType: SyblLicenseTypeEnum | undefined;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  longitude: any | undefined;
  latitude: any | undefined;
  gpsLocation: any | undefined;
  phone: string;
  postalCode: string;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingCity: string;
  shippingState: string;
  shippingCountry: string;
  shippingLongitude: any;
  shippingLatitude: any;
  shippingGpsLocation: any;
  shippingPhone: string;
  shippingPostalCode: string;
  date_time: Date | undefined;
  dateModified: Date | undefined;
  syblUsers: ISyblAgencyUser[];
  naicsCodes: Array<ISyblIndustryCoverage>;
  rnCodes: Array<ISyblIndustryCoverage>;
  licensedStates: Array<ISyblIndustryCoverage>;
  personalLines: boolean;
  topCarriers: Array<ISyblIndustryCoverage>;
  encrypted: boolean;
  protected: boolean;
  // User Search
  filters: Array<{ name: string; category: string }>;
  advancedSearchValues: any;
  sortDirection: string;
  sortBy: string;
  searchType: string;
  searchOperator: string;
  searchMultipleProperties: string;
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  sort: boolean;
  moreToLoad: boolean;
  maxPageIndex: number;
  lastId: string;
  displayCount: number;
  individualAccount: boolean;
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
const initialState: SyblAgencyState = {
  loaded: true,
  xApiKey: 'advisor@2024',
  tenant: undefined,
  agency_id: '',
  user: undefined,
  uuid: '',
  email: '',
  earlyAdopter: false,
  kill_bill_id: undefined,
  employeeCount: undefined,
  yearlyRevenue: undefined,
  agency_name: '',
  purchased_seats: 0,
  active_seats: 0,
  inactive_seats: 0,
  billingPeriod: undefined,
  productCategory: undefined,
  phaseType: undefined,
  subscriptionState: undefined,
  sourceType: undefined,
  currency: undefined,
  licenseType: undefined,
  address1: '',
  address2: '',
  city: '',
  country: '',
  state: '',
  longitude: undefined,
  latitude: undefined,
  gpsLocation: undefined,
  phone: '',
  postalCode: '',
  shippingAddress1: '',
  shippingAddress2: '',
  shippingCity: '',
  shippingState: '',
  shippingCountry: '',
  shippingLongitude: undefined,
  shippingLatitude: undefined,
  shippingGpsLocation: undefined,
  shippingPhone: '',
  shippingPostalCode: '',
  date_time: undefined,
  dateModified: undefined,
  syblUsers: [],
  naicsCodes: [],
  rnCodes: [],
  licensedStates: [],
  personalLines: false,
  topCarriers: [],
  encrypted: false,
  protected: false,
  filters: [
    { name: 'email', category: 'topProperties' },
    { name: 'agency_name', category: 'topProperties' },
    { name: 'lastName', category: 'topProperties' },
    { name: 'permissions', category: 'topProperties' },
    { name: 'user_id', category: 'topProperties' },
  ],
  advancedSearchValues: {},
  sortDirection: 'desc',
  sortBy: 'date_time',
  searchOperator: '',
  searchMultipleProperties: '',
  totalCount: 0,
  pageSize: 50,
  pageIndex: 0,
  sort: false,
  moreToLoad: false,
  maxPageIndex: 0,
  lastId: '',
  displayCount: 0,
  searchType: '',
  individualAccount: false
};

export const SyblAgencyStore = signalStore(
  { providedIn: 'root' },
  //withDevtools('sybl-agency'), // <-- add this
  withState(initialState),
  withSyblAgencyMethods(),
  withHooks({
    onInit(store) {
      //if (store.loadFromLocalStorage()) {
      // return;
      // }
      // store.load(1);
    },
  })
);
