/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { BehaviorSubject, pipe, switchMap, tap } from 'rxjs';
import {
  AuthBackendService,
  AuthCookieService,
  BrowserInfoService,
} from '@sybl/feature-auth-data-access';
import { InteractionsState } from './interactions.state';
import { ISessionResponse } from '@sybl/feature-auth-models';
import {
  IWebSocketMessage,
  WebSocketMessage,
} from '@sybl/feature-websocket-models';
import {
  DocumentStatusEnum,
  IDocumentSaveError,
} from '@sybl/feature-documents-models';
import { UUID } from '@sybl/common-models';
import { ISyblInteraction, SyblChat } from '@sybl/feature-sybl-models';

export function withInteractionsMethods() {
  return signalStoreFeature(
    { state: type<InteractionsState>() },
    withMethods((state) => ({
      receivedVote(vote: ISyblInteraction) {
        //

        patchState(state, {});
      },
      updateVote(chat_id, context_filter) {
        /*  const chats = state.chats();
        const current = chats[chat_id];

        if (current) {
          const updateEntity = { ...current, context_filter: context_filter };
          console.log('updateEntity', updateEntity);
          const newEntities = {
            ...current,
            [chat_id]: updateEntity,
          };
          console.log('setContext method');
          return patchState(state, { chats: newEntities });
        }
        */
      },
      voteAttempt(interaction) {
        const ids = [...state.ids()];
        const uniqIds = Array.from(new Set([...ids, interaction.msg_id]));
        const uuid: string = interaction.msg_id;
        const newDoc = { ...state.entities()[uuid], ...interaction };
        const updateEntities = { ...state.entities(), [uuid]: newDoc };

        patchState(state, {
          ids: uniqIds,
          entities: updateEntities,
          selectedUuid: interaction.msg_id,
        });
      },
      getItem(msg_id){
        if(msg_id && state.entities()){
          const entity = state.entities()[msg_id];
          return entity;
        }else return undefined
      },
      receivedItemsFromServer(votes){
        const voteIds = votes.map((entry:ISyblInteraction)=>entry.msg_id);
        const ids = [...state.ids(), voteIds]
        const uniqIds = Array.from(new Set([...ids]))
        const newImageEntities = votes.reduce((entities: { [msg_id: string]: ISyblInteraction }, vote: any) => {
            return Object.assign(entities, {
                
                [vote.msg_id]: vote
            })
        }, {})

        const newEntities = { ...state.entities, ...newImageEntities }


        patchState(state,
            {ids: uniqIds,
            entities: newEntities,
          }
        )
    },
    receivedFromServer(vote){
        const ids = [...state.ids()]
        const uniqIds = Array.from(new Set([...ids, vote.msg_id]))
        const uuid:string = vote.msg_id;
        const newDoc = { ...state.entities()[uuid], ...vote }
        const updateEntities = { ...state.entities(), [uuid]: newDoc }

        patchState(state,
            {ids: uniqIds,
            entities: updateEntities,
            selectedUuid: vote.msg_id
        }
        )
    },

    }))
  );
}
