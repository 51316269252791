/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  HttpClient,
  HttpParams,
  HttpUrlEncodingCodec,
} from '@angular/common/http';

import { map, tap, catchError } from 'rxjs/operators';
import {
  Observable,
  of,
  BehaviorSubject,
  Subject,
  empty,
  throwError as observableThrowError,
} from 'rxjs';

//import { contentHeaders } from '@sybl/common-models';
import { UUID } from '@sybl/common-models';
import {
  IAuth,
  IAuthResponse,
  ISessionResponse,
  ISessionStatus,
} from '@sybl/feature-auth-models';
import { AuthCookieService } from './auth-cookie-service';
import { BrowserInfoService } from './browser-info.service';

@Injectable()
export class AuthBackendService {
  private userServer: string;
  private loginUrl;
  private logoutUrl;
  private signUpUrl;
  private forgotPasswordUrl;
  private changePasswordUrl;
  private checkPasswordUrl;
  private getUsernameUrl;
  private getEmailAddressUrl;
  private checkStatusUrl;
  private tempUserUrl;
  private teamSignUpUrl;
  private teamMemberSignUpUrl;
  private individualMemberSignUpUrl;

  private registeredAvatar;

  constructor(
    @Inject('appUrls') appUrls: any,
    private http: HttpClient,
    private router: Router,
    private authCookieService: AuthCookieService,
    private location: Location,
    private browserInfoService: BrowserInfoService,
  ) {
    this.userServer = appUrls.AUTH_URL;
    this.registeredAvatar = appUrls.REGISTERED_AVATAR;

    //+ ':' + this.authPort;
    this.loginUrl = this.userServer + '/auth/login';
    this.logoutUrl = this.userServer + '/auth/logout';
    this.signUpUrl = this.userServer + '/auth/signUp';
    this.forgotPasswordUrl = this.userServer + '/auth/forgot-password';
    this.changePasswordUrl = this.userServer + '/auth/change-password';
    this.getUsernameUrl = this.userServer + '/auth/getUsername';
    this.getEmailAddressUrl = this.userServer + '/auth/getEmail';
    this.checkStatusUrl = this.userServer + '/auth/checkStatus';
    this.tempUserUrl = this.userServer + '/auth/signUpTemp';
    this.checkPasswordUrl = this.userServer + '/auth/check-password';
    this.teamSignUpUrl = this.userServer + '/auth/teamSignUp';
    this.teamMemberSignUpUrl = this.userServer + '/auth/teamMemberSignUp';
    this.individualMemberSignUpUrl = this.userServer + '/auth/individualSignUp';
  }

  checkUsername(username: string): Observable<any> {
    return this.http
      .post(this.getUsernameUrl, { username }, { withCredentials: true })
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  // headers: contentHeaders,
  checkPassword(password: string): Observable<any> {
    return this.http
      .post(this.checkPasswordUrl, { password }, { withCredentials: true })
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  checkEmail(email: string): Observable<any> {
    return this.http
      .post(this.getEmailAddressUrl, { email }, { withCredentials: true })
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  login(email: string, password: string): Observable<any> {
    const getCurrentCookie = this.authCookieService.getCookie('auth');

    if (getCurrentCookie) {
      // headers: contentHeaders,
      //contentHeaders.set('Authorization', getCurrentCookie)
    }
    // email can be an email or a username, passport requires 'email' property
    // headers: contentHeaders,
    return this.http
      .post(this.loginUrl, { email, password }, { withCredentials: true })
      .pipe(
        tap((response: any) => {
          const userJson = response.user; //['results'] //.json();
          // If did not respond with a user return an error.
          if (response.error != null) {
            const error = {
              authAttempt: response.error.authAttempt,
              wrongPassword: response.error.wrongPassword,
              lockUntil: response.error.lockUntil,
              tries: response.error.tries,
            };
            return { error };
          }

          if (userJson.user_id !== undefined) {
            return userJson;
          }

          return null;
        }),
        catchError((err) => {
          console.warn('Error on login', err);
          return err;
        }),
      );
  }

  logoutUser(): Observable<any> {
    //Was Observable Observable<HttpResponse>
    //TODO need to clean this up??? Though if there is not a cookie in theory you are already logged out?

    const getCurrentCookie: any = this.authCookieService.getCookie('auth');
    this.authCookieService.deleteCookie('auth');
    this.authCookieService.deleteCookie('connect.sid');
    this.authCookieService.deleteCookie('io');

    if (getCurrentCookie !== undefined && getCurrentCookie !== '') {
      const sessionId = getCurrentCookie['sessionId'];
      const user_id = getCurrentCookie['user_id'];
      const logoutBody = { sessionId: sessionId, user_id: user_id };
      this.authCookieService.deleteCookie('auth');
      this.authCookieService.deleteCookie('connect.sid');
      this.authCookieService.deleteCookie('io');
      this.authCookieService.deleteAllCookies();
      this.router.navigate(['/home']);

      //headers: contentHeaders,
      return this.http
        .post(this.logoutUrl, logoutBody, { withCredentials: true })
        .pipe(
          map((response) => {
            location.reload();
            return response;
          }),
        );
    } else {
      // headers: contentHeaders,
      return this.http.post(this.logoutUrl, { withCredentials: true }).pipe(
        map((response) => {
          return response;
        }),
      );
    }
  }

  signUp(signUpInfo: any): Observable<any> {
    //Look to see if there is a cookie already in.
    const email: string = signUpInfo.email,
      siteName: string = signUpInfo.siteName,
      username: string = signUpInfo.username,
      password: string = signUpInfo.password,
      firstName: string = signUpInfo.firstName,
      lastName: string = signUpInfo.lastName,
      earlyAdopter: boolean = signUpInfo.earlyAdopter,
      avatar: string = this.registeredAvatar;
    //Look to see if there is a cookie already in.
    const getCurrentCookie: any = this.authCookieService.getCookie('auth');

    const browserInfo = this.browserInfoService.browserInfo();
    const body = {
      email,
      siteName,
      password,
      username,
      firstName,
      lastName,
      browserInfo,
      avatar,
    };

    if (getCurrentCookie) {
      // I do not believe this works with XHR
      // headers: contentHeaders,
      // contentHeaders.set('Authorization', currentCookie)
    }

    //Set the authorization on the header to the cookie to the currentHeaders... TODO clean up
    // headers: contentHeaders,
    return this.http.post(this.signUpUrl, body, { withCredentials: true }).pipe(
      map((response: any) => {
        //Look at doing this in effect and doing so reactively.
        const status = 0;
        const userJson = response.user;
        if (userJson.user_id !== undefined) {
          //Should only set localStorage jwt if the response is the User, Should also change to a cookie.
          //let sessionObject = this.getNewSessionObject(userJson)
          // headers: contentHeaders,
          //contentHeaders.set('Authorization', userJson.sessionId)

          //Navigate to the home page or whereever it is we want to after login success.
          //ISSUE I believe navigating home before the cookie is registered is causing tempUser to get called

          // Not routing to user profile anymore directly to syblChat.
          // this.router.navigateByUrl('/user/edit-profile/info');
          this.router.navigateByUrl('/sybl/chat');
          return userJson;
        } else {
          //Need to rate limit this on the server side or front end from sending again after so many tries.
          return { signUp: 'Fail' };
        }
      }),
      catchError((err) => {
        console.warn('Error signing up', err);
        return err;
      }),
    );
  }

  checkAuthStatus(): Observable<any> {
    //get the sessionCookie.
    // If there is an existing cookie we just need to update the session, and make sure cookie is still valid on the server.
    // If there is not an existing cookie, create new temporary User.
    const getCurrentCookie = this.authCookieService.getCookie('auth');
    if (
      getCurrentCookie === undefined ||
      getCurrentCookie == null ||
      getCurrentCookie === 'null' ||
      getCurrentCookie === ''
    ) {
      return this.dumpCookieGetNewSession();
    } else {
      //Grab the parameters from the cookie.
      //Pass the sessionId, user_id, ipAddr, and browserInfo to server. and the last checkedTime to this.

      const params = new HttpParams().set('cookie', getCurrentCookie);
      if (getCurrentCookie) {
        //statusCheck, looks at the session database to see if there is a current valid session with the sessionId from the cookie.
        // If there is a valid session active, update the length of time until exploration.
        // If there is not a valid session will, need to check UserDB for the user attempting to login.
        // If there is a valid user, create new session token
        // If there is not a valid user, respond so.
        // headers: contentHeaders,
        return this.http
          .post<ISessionResponse>(this.checkStatusUrl, params, {
            withCredentials: true,
          })
          .pipe(
            map((sessionResponse: ISessionResponse) => {
              return sessionResponse;
            }),
            catchError((err) => {
              return err; //
            }),
          );
      } else {
        console.warn('I should not be here cookie null check authStatus');
        return of();
      }
    }
  }

  dumpCookieGetNewSession(): Observable<any> {
    this.authCookieService.deleteCookie('auth');
    this.authCookieService.deleteCookie('connect.sid');
    this.authCookieService.deleteCookie('io');

    const username = 'guest:' + new UUID().generate();
    const email = username + '@guest.fansay.com';
    const avatar = 'default.svg';
    //Can make avatrs a random image to begin with,
    // headers: contentHeaders,
    return this.http
      .post(
        this.tempUserUrl,
        { email, username, avatar },
        { withCredentials: true },
      )
      .pipe(
        map((response: any) => {
          //ISessionResponse
          //Look at doing this in effect and doing so reactively.
          const status = 0;
          const error = response.error;
          const userJson = response.user;

          if (userJson != null) {
            //Should only set localStorage jwt if the response is the User, Should also change to a cookie.

            // Might want to look at whether or not should be using avatar here.

            const user: ISessionStatus = {
              _id: userJson._id,
              user_id: userJson.user_id,
              email: userJson.email,
              tenant: userJson.tenant,
              avatar: userJson.avatar,
              earlyAdopter: userJson.earlyAdopter,
              username: userJson.username,
              jwtToken: userJson.jwtToken,
              refreshToken: userJson.refreshToken,
              expires: userJson.expires,
              sessionId: userJson.sessionId,
              permissions: userJson.permissions,
            };

            return { user: user, error: error };
          } else {
            return error;
          }
        }),
        catchError((err) => {
          console.warn('Error', err);
          return err;
        }),
      );
  }

  changePassword(user_id, password, jwToken, currentPassword) {
    const body = {
      user_id,
      password,
      jwToken,
      currentPassword,
    };

    return this.http
      .post(this.changePasswordUrl, body, { withCredentials: true })
      .pipe(
        map((response: any) => {
          this.authCookieService.deleteCookie('auth');
          this.authCookieService.deleteCookie('connect.sid');
          this.authCookieService.deleteCookie('io');
          //ISessionResponse
          //Look at doing this in effect and doing so reactively.
          const status = 0;
          const error = response.error;
          const userJson = response.user;

          if (userJson != null) {
            //Should only set localStorage jwt if the response is the User, Should also change to a cookie.

            // Might want to look at whether or not should be using avatar here.

            const user: ISessionStatus = {
              _id: userJson._id,
              user_id: userJson.user_id,
              email: userJson.email,
              avatar: userJson.avatar,
              earlyAdopter: userJson.earlyAdopter,
              username: userJson.username,
              tenant: userJson.tenant,
              jwtToken: userJson.jwtToken,
              refreshToken: userJson.refreshToken,
              expires: userJson.expires,
              sessionId: userJson.sessionId,
              permissions: userJson.permissions,
            };

            return { user: user, error: error };
          } else return { error: error };
        }),
        catchError((err) => {
          console.warn('Error', err);
          return err;
        }),
      );
  }

  createTeamAccount(signUpInfo: any): Observable<any> {
    //Look to see if there is a cookie already in.
    const email: string = signUpInfo.email,
      agency_name: string = signUpInfo.agency_name,
      agency_id: string = signUpInfo.agency_id,
      address1: string = signUpInfo.address1,
      address2: string = signUpInfo.address2,
      city: string = signUpInfo.city,
      state: string = signUpInfo.state,
      postalCode: string = signUpInfo.postalCode,
      productId: string = signUpInfo.productId,
      username: string = signUpInfo.email,
      firstName: string = signUpInfo.firstName,
      lastName: string = signUpInfo.lastName,
      earlyAdopter: boolean = signUpInfo.earlyAdopter,
      tenant: string = signUpInfo.tenant,
      min_seats: number = signUpInfo.min_seats,
      licenseType: any = signUpInfo.licenseType,
      months: any = signUpInfo.months,
      user_id: any = signUpInfo.user_id,
      stripeTestId = signUpInfo.stripeTestId,
      stripeProdId = signUpInfo.stripeProdId,
      killBillProdId = signUpInfo.killBillProdId;
    //Look to see if there is a cookie already in.
    const getCurrentCookie: any = this.authCookieService.getCookie('auth');
    const browserInfo = this.browserInfoService.browserInfo();
    const body = {
      email,
      agency_name,
      address1,
      agency_id,
      address2,
      firstName,
      lastName,
      city,
      earlyAdopter,
      state,
      postalCode,
      productId,
      username,
      browserInfo,
      tenant: tenant,
      min_seats: min_seats,
      licenseType: licenseType,
      months: months,
      user_id: user_id,
      stripeTestId: stripeTestId,
      stripeProdId: stripeProdId,
      killBillProdId: killBillProdId,
    };

    if (getCurrentCookie) {
      // I do not believe this works with XHR
      // headers: contentHeaders,
      // contentHeaders.set('Authorization', currentCookie)
    }

    //Set the authorization on the header to the cookie to the currentHeaders... TODO clean up
    // headers: contentHeaders,

    return this.http
      .post(this.teamSignUpUrl, body, { withCredentials: true })
      .pipe(
        map((response: any) => {
          //Look at doing this in effect and doing so reactively.
          const status = 0;
          const userJson = response.user;
          if (userJson.user_id !== undefined) {
            //Should only set localStorage jwt if the response is the User, Should also change to a cookie.

            const codec = new HttpUrlEncodingCodec();

            const stringUrl = JSON.stringify({
              productId: productId,
              months: months,
              user_id: user_id,
            });
            const encodedValue = codec.encodeValue(stringUrl); // Encodes the value as 'Hello%20World%21'

            if (body.tenant === 'sybl') {
              this.router.navigate(['/subscriptions/checkout/' + encodedValue]);
            } else {
              this.router.navigate([
                '/tenant/checkout/' + tenant + '/' + encodedValue,
              ]);
            }

            return userJson;
          } else {
            //Need to rate limit this on the server side or front end from sending again after so many tries.
            return { signUp: 'Fail' };
          }
        }),
        catchError((err) => {
          console.warn('Error signing up', err);
          return err;
        }),
      );
  }

  createIndividualAccount(signUpInfo: any): Observable<any> {
    //Look to see if there is a cookie already in.
    const email: string = signUpInfo.email,
      agency_name: string = signUpInfo.agency_name,
      address1: string = signUpInfo.address1,
      address2: string = signUpInfo.address2,
      agency_id: string = signUpInfo.agency_id,
      city: string = signUpInfo.city,
      earlyAdopter: boolean = signUpInfo.earlyAdopter,
      state: string = signUpInfo.state,
      postalCode: string = signUpInfo.postalCode,
      productId: string = signUpInfo.productId,
      username: string = signUpInfo.email,
      firstName: string = signUpInfo.firstName,
      tenant: string = signUpInfo.tenant,
      lastName: string = signUpInfo.lastName;

    //Look to see if there is a cookie already in.
    const getCurrentCookie: any = this.authCookieService.getCookie('auth');

    const browserInfo = this.browserInfoService.browserInfo();
    const body = {
      email,
      agency_id,
      agency_name,
      address1,
      address2,
      city,
      earlyAdopter,
      state,
      postalCode,
      productId,
      username,
      browserInfo,
      firstName,
      lastName,
      tenant,
    };

    if (getCurrentCookie) {
      //
    }

    //Set the authorization on the header to the cookie to the currentHeaders... TODO clean up
    // headers: contentHeaders,
    return this.http
      .post(this.individualMemberSignUpUrl, body, { withCredentials: true })
      .pipe(
        map((response: any) => {
          //Look at doing this in effect and doing so reactively.
          const status = 0;
          const userJson = response.user;
          if (userJson.user_id !== undefined) {
            //Should only set localStorage jwt if the response is the User, Should also change to a cookie.
            const codec = new HttpUrlEncodingCodec();

            const stringUrl = JSON.stringify({ productId: productId });
            const encodedValue = codec.encodeValue(stringUrl); // Encodes the value as 'Hello%20World%21'

            if (body.tenant === 'sybl') {
              this.router.navigate(['/subscriptions/checkout/' + encodedValue]);
            } else {
              this.router.navigate([
                '/tenant/checkout/' + tenant + '/' + encodedValue,
              ]);
            }

            return userJson;
          } else {
            //Need to rate limit this on the server side or front end from sending again after so many tries.
            return { signUp: 'Fail' };
          }
        }),
        catchError((err) => {
          console.warn('Error signing up', err);
          return err;
        }),
      );
  }
}
