import { ILocation, IPaginationParams } from '../interfaces/';

//implements IPaginationParams{
export interface IAgencySearch extends IPaginationParams {
  syblPermissions: string;
  accountStatus: string;
  agency_id: string;
}

export class AgencySearch implements IAgencySearch {
  syblPermissions: string;
  accountStatus: string;
  agency_id: string;
  sortDirection: string;
  sortBy: string;
  searchField?: string;
  fieldOne: any;
  fieldTwo: any;
  category?: string;
  location?: ILocation;
  searchOperator?: string;
  searchOperatorTwo?: string;
  searchMultipleProperties?: string;
  maxDistance?: number;
  pageSize: number;
  pageIndex: number;
  moreToLoad?: boolean;
  maxPageIndex?: number;
  lastId?: string;
  totalCount?: number;
  loaded: boolean;
  lastSearchOne: any;
  lastSearchTwo?: any;
  options?: string;
  variableType: string;

  constructor(params?) {
    this.syblPermissions = 'Any';
    this.accountStatus = 'Any';  
    this.sortDirection = 'desc';
    this.agency_id = null
    this.searchOperator = '$eq';
    this.searchOperatorTwo = undefined;
    this.searchField = undefined;
    this.searchMultipleProperties = '$or';
    this.sortBy = '_id';
    this.category = '';
    this.location = { longitude: 0, latitude: 0 };
    this.fieldOne = null;
    this.fieldTwo = null;
    this.moreToLoad = false;
    this.variableType = 'string';
    this.pageIndex = 0;
    this.maxDistance = 10000;
    this.pageSize = 25;
    this.maxPageIndex = 0;
    this.lastId = undefined;
    this.totalCount = 0;
    this.loaded = true;
    this.lastSearchOne = '';
    this.lastSearchTwo = null;
    this.options = '';
  
    if(params.agency_id!== undefined){this.agency_id = params.agency_id}
    if(params.syblPermissions!==undefined){this.syblPermissions = params.syblPermissions}
    if(params.accountStatus!==undefined){this.accountStatus = params.accountStatus}
    if(params.sortDirection!==undefined){this.sortDirection = params.sortDirection}
    if(params.searchOperator!==undefined){this.searchOperator = params.searchOperator}
    if(params.searchOperatorTwo!==undefined){this.searchOperatorTwo = params.searchOperatorTwo}
    if(params.searchField!==undefined){this.searchField = params.searchField}
    if(params.searchMultipleProperties!==undefined){this.searchMultipleProperties = params.searchMultipleProperties}
    if(params.sortBy!==undefined){this.sortBy = params.sortBy}
    if(params.category!==undefined){this.category = params.category}
    if(params.location!==undefined){this.location = params.location}
    if(params.fieldOne!==undefined){this.fieldOne = params.fieldOne}
    if(params.fieldTwo!==undefined){this.fieldTwo = params.fieldTwo}
    if(params.moreToLoad!==undefined){this.moreToLoad = params.moreToLoad}
    if(params.variableType!==undefined){this.variableType = params.variableType}
    if(params.pageIndex!==undefined){this.pageIndex = params.pageIndex}
    if(params.maxDistance!==undefined){this.maxDistance = params.maxDistance}
    if(params.pageSize!==undefined){this.pageSize = params.pageSize}
    if(params.maxPageIndex!==undefined){this.maxPageIndex = params.maxPageIndex}
    if(params.lastId!==undefined){this.lastId = params.lastId}
    if(params.totalCount!==undefined){this.totalCount = params.totalCount}
    if(params.loaded!==undefined){this.loaded = params.loaded}
    if(params.lastSearchOne!==undefined){this.lastSearchOne = params.lastSearchOne}
    if(params.lastSearchTwo!==undefined){this.lastSearchTwo = params.lastSearchTwo}
    if(params.options!==undefined){this.options = params.options}

    }
}

export class PaginationParams implements IPaginationParams {
  sortDirection: string;
  sortBy: string;
  searchField?: string;
  fieldOne: any;
  fieldTwo: any;
  category?: string;
  location?: ILocation;
  searchOperator: string;
  searchOperatorTwo?: string;
  searchMultipleProperties: string;
  pageSize: number;
  pageIndex: number;
  variableType: string;
  maxDistance?: number; //miles;
  moreToLoad?: boolean;
  maxPageIndex?: number;
  lastId?: string;
  loaded: boolean;
  totalCount?: number;
  lastSearchOne: any;
  lastSearchTwo: any;
  options: string;
  sortByProp: string;

  constructor(params?: any) {
    this.sortDirection = 'desc'; //or asc   When interacting with AdvancedSearch need to transform the value of desc:true | false
    this.searchOperator = '$eq';
    this.searchOperatorTwo = undefined;
    this.searchField = undefined;
    this.searchMultipleProperties = '$or';
    this.sortBy = '_id';
    this.category = '';
    this.location = { longitude: 0, latitude: 0 };
    this.fieldOne = null;
    this.fieldTwo = null;
    this.moreToLoad = false;
    this.variableType = 'string';
    this.pageIndex = 0;
    this.maxDistance = 10000;
    this.pageSize = 25; //Default pageSize set to 25
    this.maxPageIndex = 0; //Used for pagination, don't make request to backend if all ready have the data
    this.lastId = undefined;
    this.totalCount = 0;
    this.loaded = true;
    this.lastSearchOne = '';
    this.lastSearchTwo = null;
    this.options = '';
    this.sortByProp = '';

    if (params !== undefined) {
      if (params.sortDirection !== undefined && params.sortDirection !== '') {
        this.sortDirection = params.sortDirection;
      }
      if (params.searchField !== undefined) {
        this.searchField = params.searchField;
      }
      if (params.fieldOne !== undefined) {
        this.fieldOne = params.fieldOne;
      }
      if (params.fieldTwo !== undefined) {
        this.fieldTwo = params.fieldTwo;
      }
      if (params.location !== undefined) {
        this.location = params.location;
      }
      if (params.loaded !== undefined) this.loaded = params.loaded;
      if (params.category !== undefined) {
        this.category = params.category;
      }
      if (params.totalCount !== undefined) this.totalCount = params.totalCount;

      if (params.sortBy !== undefined && params.sortBy !== '')
        this.sortBy = params.sortBy;
      if (params.variableType !== undefined && params.variableType !== '')
        this.variableType = params.variableType;
      if (params.pageIndex !== undefined) this.pageIndex = params.pageIndex;
      if (params.maxDistance !== undefined) {
        this.maxDistance = params.maxDistance;
      }
      if (params.maxPageIndex !== undefined)
        this.maxPageIndex = params.maxPageIndex;
      if (params.sortByProp !== undefined) {
        this.sortByProp = params.sortByProp;
      }
      if (params.sortDirection !== undefined)
        this.sortDirection = params.sortDirection;

      if (params.searchMultipleProperties !== undefined)
        this.searchMultipleProperties = params.searchMultipleProperties;

      if (params.pageSize !== undefined && params.pageSize !== '')
        this.pageSize = params.pageSize;

      if (params.lastId !== undefined && params.lastId !== '')
        this.lastId = params.lastId;

      if (params.searchOperator !== undefined)
        this.searchOperator = params.searchOperator;

      if (params.lastSearchOne !== undefined)
        this.lastSearchOne = params.lastSearchOne;

      if (params.lastSearchTwo !== undefined)
        this.lastSearchTwo = params.lastSearchTwo;

      if (params.options !== undefined) this.options = params.options;
    }
  }
}
