import { IWebSocketMessage } from '../interfaces';
import { EnumWSMessageStatus } from '../enums';
import { IDocumentDetails } from '@sybl/feature-documents-models';
import { UUID } from '@sybl/common-models'
import { ILocation } from '@sybl/common-models';

export class WebSocketMessage implements IWebSocketMessage {
    public uuid: string
    public user_id?: string
    public msg: string
    public jwtToken: string
    public status: EnumWSMessageStatus
    public webSocketUrl: string
    public date_time?: Date
    public lastMsgTime?: number
    public interval?: number
    public attempts: number
    public document?: IDocumentDetails
    public location?: ILocation
    public payload?: any
    public percentComplete?: number
    public acknowledged?: boolean
    public connected?: boolean;

    constructor(params: IWebSocketMessage|IWebSocketMessage){
        this.uuid = '';
        this.user_id = params.user_id;
        this.status = 0;
        this.webSocketUrl = '';
        this.interval = 0;
        this.attempts = 0;
        this.jwtToken = ''
        this.percentComplete = 0;
        this.payload = null;
        this.location = { };
        this.document = undefined;
        this.acknowledged = false;
        this.connected = false;

        if(params.uuid !== undefined){
            this.uuid = params.uuid;
        }else{
            this.uuid = new UUID().generate();
        }

        if(params.msg !== undefined){
            this.msg = params.msg;
        }else{ 
            this.msg = '';
        }

        if(params.jwtToken !== undefined){
            this.jwtToken = params.jwtToken;
        }

        if(params.status !== undefined){
            this.status = params.status;
        }else{ 
            this.status = 0;
        }

        if(params.location !== undefined){
            this.location = params.location;
        }

        if(params.webSocketUrl !== undefined){
            this.webSocketUrl = params.webSocketUrl;
        }

        if(params.date_time !== undefined){
            this.date_time = params.date_time;
        }else{
            this.date_time = new Date()
         }

        if(params.lastMsgTime !== undefined){
            this.lastMsgTime = params.lastMsgTime;
        }

        if(params.interval !== undefined){
            this.interval = params.interval;
        }

        if(params.attempts !== undefined){
            this.attempts = params.attempts;
        }

        if(params.payload !== undefined){
            this.payload = params.payload;
        }

        // If document is not reparsed into JSON, it errors ngrx store with, Type Error: Cannot freeze 
        if(params.document !== undefined ){

            this.document = JSON.parse(JSON.stringify(params.document));
        }

        if(params.percentComplete !== undefined){
            this.percentComplete = params.percentComplete;
        }

        if(params.acknowledged !== undefined){
            this.acknowledged = params.acknowledged;
        }

        if(params.connected !== undefined){
            this.connected = params.connected
        }
    }



}