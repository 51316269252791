import { ITablePagination } from '../interfaces'
export class TablePagination implements ITablePagination{
    length: number;
    pageIndex: number;
    pageSize: number;
    direction?: string;
    active?: string;
    previousPageIndex?: number;

    constructor(params?:any){
        this.length = 0;
        this.pageIndex = 0;                
        this.pageSize = 25; 
        this.direction = "desc";
        this.active = "_id"; //Defaul to sortBy id, active is sortBy in MatTable.
        this.previousPageIndex = 0;

        if(params !== undefined){
            if(params.direction !== undefined && params.direction !== "" ){ this.direction = params.direction;} 
            
            if(params.active !== undefined) this.active = params.active;
            
            if(params.length !== undefined && params.length !== "") this.length = params.length;

            if(params.pageIndex !== undefined) this.pageIndex = params.pageIndex;
            
            if(params.pageSize !== undefined && params.pageSize !== "") this.pageSize = params.pageSize;

            if(params.previousPageIndex !== undefined && params.previousPageIndex !=="")this.previousPageIndex = params.previousPageIndex;
        }
    }
}