import { Injectable } from '@angular/core'
import { IBrowserInfo } from '@sybl/common-models'
// import { DeviceDetectorService } from 'ngx-device-detector';

//TODO Important to revisit this. 
@Injectable()
export class BrowserInfoService { 
    constructor(
        //private deviceInfo:DeviceDetectorService
    ){}
    browserInfo() {
        //const deviceInfo = this.deviceInfo.getDeviceInfo()

        // Chck the Window and the ua-parser-js details to submit the browser Information.
        
        //const clientOS = deviceInfo.os;
        //const clientOSVersion = deviceInfo.os_version
//
        //const clientUA = deviceInfo.userAgent;

        const deviceType = '';

        //if(this.deviceInfo.isMobile()){ deviceType = 'mobile'} 
        //else if(this.deviceInfo.isTablet()){ deviceType = 'tablet'}
        //else if(this.deviceInfo.isDesktop()){ deviceType = 'desktop'}

        const browserInfo: IBrowserInfo = {
            maxWidth: window.screen.availWidth,
            maxHeight: window.screen.availHeight,
            browserWidth: window.innerWidth,
            browserHeight: window.innerHeight,
            //browserName: deviceInfo.browser,
            //browserVersion: deviceInfo.browser_version,

            //device: deviceInfo.device, // Change to see if this is a desktop
            deviceType: deviceType,
           // osName: clientOS,
           // osVers: clientOSVersion
        }

        //Must check to see if localStorage is available, it is not in private mode
        return browserInfo
    }
}