import {
  DocumentStatusEnum,
  IDocumentAdditonsDeletions,
  IDocumentEdits,
  IDocumentUpdate,
  IFieldChanges,
} from '@sybl/feature-documents-models';
import {  ISuggestedQuestion } from './sybl-suggested-question.model'
export interface ISyblMessageGroup {
  date_time: Date;
  prompt?: ISyblMessage;
  prompt_id: string;
  response?: ISyblMessage;
  active: boolean;
}


export interface ISyblMessage {
  pair_id?: string;
  attachment_path: string;
  linq_version: string;
  version_id?: string; // Sent from Server saved as linq_version in MongoDB
  date_time: Date;
  chat_id: string;
  context_filter: string;
  'x-api-key': string;
  suggested_question:ISuggestedQuestion | any;
  suggested: boolean;
  user_id: any;
  response_type: string;
  editMessage?: string;
  hidden?: boolean;
  msg_type: string;
  msg_from: string;
  msg_id: string; // uuid
  msg_text: string;
  msg_file_path: string;
  msg_downloadable: boolean;
  env_name: string;
  business_id?: string;
}

export class SyblMessage implements ISyblMessage {
  chat_id: string;
  env_name: string; //'web-dev' web-qa, web-demo,
  context_filter: string;
  suggested_question: ISuggestedQuestion | any ;
  suggested: false;
  attachment_path: string;
  linq_version: string;
  version_id?: string;
  date_time: Date;
  uuid: string;
  hidden?: boolean;
  response_type: string;
  user_input: string;
  pair_id: string; // Used to group messages, based on the input which originated it, taking a cue from google on how they display in their chatAI
  user_id: any;
  editMessage?: string;
  'x-api-key': string;
  msg_id: string;
  msg_downloadable: boolean;
  msg_file_path: string;
  msg_from: string;
  msg_text: string;
  msg_type: string;
  msg_txt?: string; // Temp Fix
  business_id?: string;

  constructor(params) {
    if (params) {
      this.hidden = params.hidden;
      this.attachment_path = params.attachment_path;
      this.linq_version = params.linq_version;
      this.version_id = params.version_id;
      this.suggested_question = params.suggested_question;
      this.suggested = params.suggested;
      this.context_filter = params.context_filter;
      this.date_time = params.date_time;
      this.uuid = params.uuid;
      this.user_input = params.user_input;
      this.chat_id = params.chat_id;
      this['x-api-key'] = params['x-api-key'];
      this.user_id = params.user_id;
      this.response_type = params.response_type;
      this.editMessage = params.editMessage;
      this.msg_text = params.msg_text;
      this.msg_type = params.msg_type;
      this.msg_id = params.msg_id;
      this.msg_downloadable = params.msg_downloadable;
      this.msg_from = params.msg_from;
      this.msg_file_path = params.msg_file_path;
      this.msg_txt = params.msg_text;
      this.env_name = params.env_name;
      this.business_id = params.business_id;
    }
  }
}

export class SyblMessageUpdate extends SyblMessage implements IDocumentUpdate {
  master_id?: string;
  flagged?: boolean;
  priority?: number;
  editor_id?: any;
  editor_type: string;
  status?: DocumentStatusEnum;
  wasReversion: boolean;
  additions: IDocumentAdditonsDeletions[];
  deletions: IDocumentAdditonsDeletions[];
  changes: IDocumentEdits[];
  fieldChanges: IFieldChanges[];
  reasonForUpdate: string;

  constructor(params?: any) {
    super(params);
    this.msg_text = params.msg_text;
    this.context_filter = params.context_filter;
    this.date_time = params.date_time;
    this.uuid = params.uuid;
    this.master_id = params.master_id;
    this.flagged = params.flagged;
    this.priority = params.priority;
    this.editor_id = params.editor_id;
    this.editor_type = params.editor_type;
    this.status = params.status;
    this.wasReversion = params.wasReversion;
    this.additions = params.additions;
    this.deletions = params.deletions;
    this.changes = params.changes;
    this.fieldChanges = params.fieldChanges;
    this.reasonForUpdate = params.reasonForUpdate;
  }
}
