import { Injectable, Inject } from '@angular/core';

@Injectable()
export class AppUrlsService {
    appUrls;

    constructor( @Inject('appUrls') appUrls: any
    ) { this.appUrls = appUrls; }

    getAppUrls(){

        return this.appUrls
    
    
    }

}